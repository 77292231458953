
import styled from 'styled-components'
import * as palette from '../../styles/variables'
import { device } from '../../styles/device'


const PokerHeroStyled = styled.div`
    /* background-image: linear-gradient(120deg,#2b10ec,hsl(283, 94%, 29%)); */
    height: 600px;
    position: relative;

    @media ${device.tablet} {
        height: 600px;
        margin-top: ${palette.headerHeight};
    }

    .pokerContent {
        padding-top: 0;
        height: 100%;
        .container {
            height: 100%;

        }
    }

    .bannerImage2 {
        position: absolute;
        width: 100%;
        top: 0;
    }

    @keyframes swing {
        0% { transform: rotate(3deg); }
        100% { transform: rotate(-3deg); }
    }
 `;

export default PokerHeroStyled